import { SvgIcon } from '../SvgIcon';
import * as React from 'react';

interface IProps {
	className?: string;
	fill?: string;
	height?: number;
	width?: number;
}

export const ExpandIcon: React.FC<IProps> = props => {
	const { className, fill = '#00AAE8', height = 20, width = 20 } = props;
	return (
		<SvgIcon
			className={`expandIcon ${className ?? ''}`}
			width={width}
			height={height}
			viewBox={`0 0 ${width} ${height}`}
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path fillRule='evenodd' clipRule='evenodd' d='M2 4H0V19C0 19.552 0.448 20 1 20H16V18H2V4Z' fill={fill} />
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M18 14H6V2H18V14ZM19 0H5C4.448 0 4 0.448 4 1V15C4 15.552 4.448 16 5 16H19C19.552 16 20 15.552 20 15V1C20 0.448 19.552 0 19 0Z'
				fill={fill}
			/>
			<path fillRule='evenodd' clipRule='evenodd' d='M13 9H16V7H13H11H8V9H11H13Z' fill={fill} />
		</SvgIcon>
	);
};
